import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import ContextConsumer from "src/layouts/Context.js"
import BodyText from "src/components/global/typography/BodyText.js"
import BigText from "src/components/global/typography/BigText.js"
import BigTitleH3 from "src/components/global/typography/BigTextH3.js"
import Swipeable from "react-swipeable"
import distances from "src/assets/styles/distances.js"
import Wrapper from "src/components/global/Wrapper"
import Divider from "src/components/global/Divider.js"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import CountUp from "react-countup"
import VizSensor from "react-visibility-sensor"

const SectionWrapper = styled(Wrapper)`
  position: relative;
  margin-top: 150px;
`
const Title = styled(BigTitleH3)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
`
const NumbersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
  gap: 20px;
`

const NumberWrapper = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 80px;
  position: relative;
  &.highlight {
    ::after {
      content: "";
      display: block;
      width: 380px;
      height: 100%;
      background-color: ${colors.mainColor};
      position: absolute;
      top: 0;
      left: -20px;
      z-index: -1;
      transform: rotate(-5deg);
      @media (max-width: ${mediaQuery.tablet}) {
        width: 200px;
      }
    }
  }
`
const Number = styled(BigTitleH3)`
  span {
    font-size: inherit;
  }
`
const NumberText = styled(BodyText)`
  font-size: ${typographySizes.l}px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}px;
  }
`

const Source = styled.a``

class Numbers extends React.Component {
  render() {
    return (
      <SectionWrapper>
        {" "}
        <SmallTitle>{this.props.Title}</SmallTitle>
        {/* <Divider /> */}
        <Title>{this.props.Description}</Title>
        <NumbersWrapper>
          {this.props.Numbers.map((number, index) => (
            <NumberWrapper
              key={index}
              className={number.Highlight ? "highlight" : ""}
            >
              <CountUp
                end={number.Number}
                start={0}
                duration={2.75}
                decimals={1}
                decimal="."
                suffix="%"
                formattingFn={value => `${value}%`}
              >
                {({ countUpRef, start }) => (
                  <VizSensor onChange={start} delayedCall partialVisibility>
                    <Number>
                      <span ref={countUpRef} />
                    </Number>
                  </VizSensor>
                )}
              </CountUp>

              <NumberText>{number.Text}</NumberText>
            </NumberWrapper>
          ))}
        </NumbersWrapper>
        <Source href={this.props.Source_link} target="_blank">
          {this.props.lang === "pl" ? "źródło*" : "source*"}
        </Source>
      </SectionWrapper>
    )
  }
}

export default Numbers
