import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import ContextConsumer from "src/layouts/Context.js"
import BodyText from "src/components/global/typography/BodyText.js"
import BigText from "src/components/global/typography/BigText.js"
import BigTitleH3 from "src/components/global/typography/BigTextH3.js"
import Swipeable from "react-swipeable"
import distances from "src/assets/styles/distances.js"
import Wrapper from "src/components/global/Wrapper"
import Divider from "src/components/global/Divider.js"
const SectionWrapper = styled.div`
  position: relative;
  margin-top: 50px;
`

const StyledWrapper = styled(Wrapper)`
  margin-bottom: 50px;
`

const Title = styled(BigTitleH3)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
`

const Description = styled(BodyText)`
  margin-left: 50%;
  width: 33.33%;
  text-align: left;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`

const ArrowHead = styled.div`
  border: solid ${colors.darkGrey};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 1px;
  transform: rotate(-45deg);
  transition: all 0.5s;
  height: 6px;
  width: 6px;
`

const ArrowHeadLeft = styled(ArrowHead)`
  transform: rotate(135deg) scale(1);
  transition: all 0.5s;
`

const ArrowHeadRight = styled(ArrowHead)`
  position: relative;
  left: -2px;
`

const ShowNext = styled.button`
  transition: all 0.5s;
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-right: 5px;
  background-color: ${colors.white};
  border: solid 1px ${colors.darkGrey};

  cursor: pointer;
  &.nondisable:hover {
    /* transform: translateX(10px);
    transition: all 0.5s; */
  }
  &.nondisable:hover ${ArrowHeadRight} {
    transform: translateX(5px) rotate(-45deg);
    transition: all 0.5s;
  }
  /* @media (max-width: ${mediaQuery.tablet}) {
    &.nondisable:hover ${ArrowHeadRight} {
      transform: translateX(0px) rotate(-45deg);
      transition: all 0.5s;
    }
  } */
`
const ShowPrev = styled.button`
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-left: 5px;
  background-color: ${colors.white};
  border: solid 1px ${colors.darkGrey};

  cursor: pointer;

  &.nondisable:hover {
    /* transform: translateX(-10px);
    transition: all 0.5s; */
  }
  &.nondisable:hover ${ArrowHeadLeft} {
    transform: translateX(-5px) rotate(135deg);
    transition: all 0.5s;
  }
`

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  width: 100px;
  /* transform: translate(0, -100%); */

  transition: all 0.5s;
  .disable {
    opacity: 0.4;
    cursor: not-allowed;
    transition: all 0.5s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
  }
`

const SliderWrapper = styled(Wrapper)`
  height: auto;
  position: relative;
  /* width: calc(100vw - ${distances.mainBigMargin}px); */
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;

  :hover ${ControlWrapper} {
  }
  @media (max-width: ${mediaQuery.tablet}) {
    overflow: visible;
  }
`

const TestimonialsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 20px;
  width: ${props =>
    props.sliderWidth
      ? `calc(${props.sliderWidth}% + ${distances.mainBigMargin}px)`
      : `calc(100% + ${distances.mainBigMargin}px)`};
  margin-left: ${props =>
    props.sliderWidth ? `calc(100% - ${props.sliderWidth}% )` : "0%"};

  @media (max-width: ${mediaQuery.tablet}) {
    width: ${`calc(100% + ${distances.smallMargin}px)`};
    margin-left: 0;
  }
`

const Testimonial = styled.div`
  width: 41.66%;
  flex-shrink: 0;
  margin-top: 70px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 10px);
  }
`

const TestimonialBody = styled(BodyText)`
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 40px;
`

const TestimonialTitle = styled(BodyText)``

class Testimonials extends React.Component {
  state = {
    currentIndex: 0,
    prevDisable: true,
    nextDisable: false,
    testimonialWidth: 0,
  }

  componentDidMount() {
    // Get the first testimonial element and its width
    const testimonialEl = document.querySelector("[data-testimonial]")
    if (testimonialEl) {
      this.setState({ testimonialWidth: testimonialEl.offsetWidth })
    }
  }

  changeSlideNext = () => {
    if (this.state.currentIndex >= this.props.Testimonials.length - 1) return

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      prevDisable: false,
      nextDisable:
        prevState.currentIndex + 1 >= this.props.Testimonials.length - 1,
    }))
  }

  changeSlidePrev = () => {
    if (this.state.currentIndex <= 0) return

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      prevDisable: prevState.currentIndex - 1 <= 0,
      nextDisable: false,
    }))
  }

  swipingLeft = e => {
    this.changeSlideNext()
    e.preventDefault()
  }
  swipingRight = e => {
    this.changeSlidePrev()
    e.preventDefault()
  }

  render() {
    return (
      <SectionWrapper>
        <StyledWrapper>
          <Title>{this.props.Title}</Title>
          <Description>{this.props.Description}</Description>
        </StyledWrapper>
        <Swipeable
          onSwipedLeft={this.swipingLeft}
          onSwipedRight={this.swipingRight}
          preventDefaultTouchmoveEvent={true}
        >
          <SliderWrapper>
            <TestimonialsWrapper sliderWidth={this.props.sliderWidth}>
              {this.props.Testimonials.map((document, index) => {
                return (
                  <Testimonial
                    data-testimonial
                    style={{
                      transform: `translateX(${
                        -this.state.currentIndex *
                        (this.state.testimonialWidth + 20)
                      }px)`,
                      transition: "transform 0.5s ease-in-out",
                    }}
                    key={index}
                  >
                    <Divider />
                    <TestimonialBody>{document.Testimonial}</TestimonialBody>
                    <TestimonialTitle>{document.Client}</TestimonialTitle>
                  </Testimonial>
                )
              })}
            </TestimonialsWrapper>

            <ControlWrapper sliderWidth={this.props.sliderWidth}>
              <ShowPrev
                className={this.state.prevDisable ? "disable" : "nondisable"}
                onClick={this.changeSlidePrev}
              >
                <ArrowHeadLeft />
                {/* pokaż wczesniej */}
              </ShowPrev>
              <ShowNext
                className={this.state.nextDisable ? "disable" : "nondisable"}
                onClick={this.changeSlideNext}
              >
                {/* pokaż dalej */}
                <ArrowHeadRight />
              </ShowNext>
            </ControlWrapper>
          </SliderWrapper>
        </Swipeable>
      </SectionWrapper>
    )
  }
}

export default Testimonials
