import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import ContextConsumer from "src/layouts/Context.js"
import BodyText from "src/components/global/typography/BodyText.js"
import BigText from "src/components/global/typography/BigText.js"
import BigTextH3 from "src/components/global/typography/BigTextH3.js"
import Swipeable from "react-swipeable"
import distances from "src/assets/styles/distances.js"
import Wrapper from "src/components/global/Wrapper"
import Divider from "src/components/global/Divider.js"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import CountUp from "react-countup"
import VizSensor from "react-visibility-sensor"

const SectionWrapper = styled(Wrapper)`
  position: relative;
  margin-top: 150px;
`
const Title = styled(BigTextH3)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
`

const StructureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
  gap: 20px;
`

const StructureItem = styled.div`
  width: 100%;
  margin-bottom: 80px;
  position: relative;
`
const StructureItemInner = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 20px;
`

const InfoWrapper = styled.div`
  width: 50%;
`

const StructureItemTitle = styled(BigTextH3)``

const ListWrapper = styled.div`
  width: 100%;
`

const CompetanceWrapper = styled.div`
  border-bottom: 2px solid ${colors.black};
  width: 100%;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  &:first-child {
    /* border-top: 2px solid ${colors.black}; */
  }
  &:last-child {
    border-bottom: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.mainColor};
    transform: translateY(-105%);
    transition: transform 0.3s ease;
    z-index: -1;
  }

  @media (min-width: ${mediaQuery.tablet}) {
    &:hover::before {
      transform: ${props => !props.isActive && "translateY(0)"};
    }
  }
`

const Number = styled.span`
  font-size: ${typographySizes.xl}px;
  color: ${colors.darkGrey};
  line-height: 1.1;
  width: 140px;
  flex-shrink: 0;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}px;
    width: 70px;
  }
`

const PlusIcon = styled.div`
  width: 44px;
  height: 44px;
  position: relative;
  /* position: absolute; */
  /* right: 0; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  &::before {
    content: "";
    position: absolute;
    top: 50%;

    left: 0;
    width: 100%;
    height: 2px;

    transform: rotate(90deg);
    background-color: ${colors.darkGrey};
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${colors.darkGrey};
  }
`

const StyledBodyText = styled(BodyText)`
  margin-top: 80px;
  width: 50%;
  margin-left: 50%;
  margin-bottom: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }
`

const AccordionContent = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
`

const CompetanceInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const CompetanceInnerInner = styled.div`
  display: flex;
  align-items: center;
`

class Accordion extends React.Component {
  state = {
    activeIndex: null,
  }

  toggleAccordion = index => {
    this.setState(prevState => ({
      activeIndex: prevState.activeIndex === index ? null : index,
    }))
  }

  render() {
    return (
      <SectionWrapper>
        {" "}
        <SmallTitle>{this.props.Title}</SmallTitle>
        <ListWrapper>
          {this.props.Structure.map((item, index) => (
            <CompetanceWrapper
              key={index}
              isActive={this.state.activeIndex === index}
            >
              <CompetanceInner onClick={() => this.toggleAccordion(index)}>
                <CompetanceInnerInner>
                  <Number>0{index + 1}</Number>
                  <StructureItemTitle>{item.Title}</StructureItemTitle>
                </CompetanceInnerInner>
                <PlusIcon
                  style={{
                    transform: `rotate(${
                      this.state.activeIndex === index ? "45deg" : "0"
                    })`,
                    transition: "transform 0.3s ease",
                  }}
                />
              </CompetanceInner>
              <AccordionContent
                ref={el => (this[`content-${index}`] = el)}
                style={{
                  height:
                    this.state.activeIndex === index
                      ? `${this[`content-${index}`]?.scrollHeight}px`
                      : "0",
                }}
              >
                <StyledBodyText>{item.Description}</StyledBodyText>
              </AccordionContent>
            </CompetanceWrapper>
          ))}
        </ListWrapper>
      </SectionWrapper>
    )
  }
}

export default Accordion
