import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import SEO from "src/components/global/SEO.js"
import ContextConsumer from "src/layouts/Context.js"
import noHangers from "src/components/global/fn/noHangers.js"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import MainBtn from "src/components/global/btn/MainBtn"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import Companies from "src/components/global/Companies/Companies"
// import InstaFeed from "src/components/global/InstaFeed.js"
import Divider from "src/components/global/Divider.js"
import Person from "../components/about/Person"
import Wrapper from "src/components/global/Wrapper"
import Img from "gatsby-image"
// import Crop from "src/components/global/Crop"

import BodyText from "src/components/global/typography/BodyText.js"
import BigText from "src/components/global/typography/BigText.js"
import BigTitleH3 from "src/components/global/typography/BigTextH3.js"
import NewBtn from "src/components/global/btn/NewBtn.js"
import Slider from "src/components/global/Slider"
import Testimonials from "src/components/about/Testimonials"
import Numbers from "src/components/about/Numbers"
import Structure from "src/components/about/Structure"
import Accordion from "src/components/global/Accordion.js"

const TeamWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MainTitle = styled(BigText)`
  margin-bottom: 40px;
  width: 75%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
`

const SubTitle = styled(BodyText)`
  margin-left: 50%;
  margin-bottom: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0%;
  }
`

const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20px;
  }
`

const ValueTextWrapper = styled.div`
  margin-bottom: 50px;
  margin-right: 8.33%;
  width: 41.67%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
    width: 75%;
    margin-right: 0;
  }
`
const ValueTitle = styled(BigTitleH3)`
  margin-bottom: 20px;
`
const Number = styled(BigTitleH3)``

const ValueBtnWrapper = styled.div`
  margin-left: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 25%;
    margin-top: 20px;
  }
`

const WhatWeDoWrapper = styled.div`
  margin-top: 100px;
`

const WhatWeDoPosition = styled.div`
  margin-bottom: 100px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50px;
  }
`

const WhatWeDoTitle = styled(BigTitleH3)`
  width: 83.33%;
  margin-top: 20px;
  margin-bottom: 20px;
  &.team-description {
    margin-bottom: 50px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0;
  }
`

const WhatWeDoDescription = styled(BodyText)`
  margin-left: 50%;
  width: 33.33%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  margin-top: 100px;
`

class About extends React.Component {
  state = { person: 500 }
  render() {
    return (
      <>
        <SEO
          noImg
          title={this.props.data.strapiAboutPageN.SEO.SEO_Title}
          description={this.props.data.strapiAboutPageN.SEO.SEO_Description}
          image={
            this.props.data.strapiAboutPageN.SEO.Seo_Img.localFile.publicURL
          }
        />
        <Navigation
          lang={this.props.pageContext.locale}
          langUrl={this.props.pageContext.langUrl}
          navBtnLink={this.props.data.strapiGlobal.Nav_btn_link}
          navBtnText={this.props.data.strapiGlobal.Nav_btn_text}
        />
        <Wrapper>
          <MainTitle>{this.props.data.strapiAboutPageN.Title}</MainTitle>
          <SubTitle>{this.props.data.strapiAboutPageN.Description}</SubTitle>

          <SmallTitle>
            {this.props.data.strapiAboutPageN.Values_Title}
          </SmallTitle>

          {this.props.data.strapiAboutPageN.Values.map((value, index) => (
            <div>
              {index !== 0 && <Divider />}

              <ValueWrapper>
                <Number>0{index + 1}</Number>
                <ValueTextWrapper>
                  <ValueTitle>{value.Title}</ValueTitle>
                  <BodyText>{value.Description}</BodyText>
                </ValueTextWrapper>
              </ValueWrapper>
            </div>
          ))}
          <ValueBtnWrapper>
            <MainBtn
              hoverText={this.props.data.strapiAboutPageN.Values_Btn.Hover}
              Mobile={this.props.data.strapiAboutPageN.Values_Btn.Mobile}
              link={`/${
                this.props.pageContext.locale === "en"
                  ? "en/contact"
                  : "kontakt"
              }`}
            >
              {this.props.data.strapiAboutPageN.Values_Btn.Main}
            </MainBtn>
          </ValueBtnWrapper>
          <WhatWeDoWrapper>
            <SmallTitle>
              {this.props.data.strapiAboutPageN.What_we_do_Title}
            </SmallTitle>
            {/* <Divider /> */}
            {this.props.data.strapiAboutPageN.What_we_do.map((item, index) => (
              <WhatWeDoPosition>
                <WhatWeDoTitle>{item.Title}</WhatWeDoTitle>
                <WhatWeDoDescription>{item.Description}</WhatWeDoDescription>
              </WhatWeDoPosition>
            ))}
          </WhatWeDoWrapper>
        </Wrapper>
        <Slider Gallery={this.props.data.strapiAboutPageN.Slider} />
        <Wrapper>
          <WhatWeDoWrapper>
            <SmallTitle>
              {this.props.data.strapiAboutPageN.How_do_we_work_Title}
            </SmallTitle>
            {/* <Divider /> */}
            {this.props.data.strapiAboutPageN.How_do_we_work.map(
              (item, index) => (
                <WhatWeDoPosition>
                  <WhatWeDoTitle>{item.Title}</WhatWeDoTitle>
                  <WhatWeDoDescription>{item.Description}</WhatWeDoDescription>
                </WhatWeDoPosition>
              )
            )}
          </WhatWeDoWrapper>
          <ImageWrapper>
            <Img
              fluid={
                this.props.data.strapiAboutPageN.Image.localFile.childImageSharp
                  .fluid
              }
            />
          </ImageWrapper>
        </Wrapper>
        <Testimonials
          Title={this.props.data.strapiAboutPageN.Testimonials_Title}
          Description={
            this.props.data.strapiAboutPageN.Testimonials_description
          }
          Testimonials={this.props.data.strapiAboutPageN.Testimonials}
        />
        <Numbers
          Title={this.props.data.strapiAboutPageN.Numbers_Title}
          Description={this.props.data.strapiAboutPageN.Numbers_description}
          Numbers={this.props.data.strapiAboutPageN.Numbers}
          Source_link={this.props.data.strapiAboutPageN.Source_link}
          lang={this.props.pageContext.locale}
        />
        <Structure
          Title={this.props.data.strapiAboutPageN.Structure_title}
          Description={this.props.data.strapiAboutPageN.Structure_description}
          Structure={this.props.data.strapiAboutPageN.Structure}
        />
        <Accordion
          Title={this.props.data.strapiAboutPageN.Trivia_title}
          Structure={this.props.data.strapiAboutPageN.Trivia}
        />
        <ContextConsumer>
          {({ data, set }) => (
            <Wrapper id="ludzie">
              <SmallTitle>
                {this.props.data.strapiAboutPageN.Team_title}
              </SmallTitle>
              {/* <Divider /> */}
              <WhatWeDoTitle className="team-description">
                {this.props.data.strapiAboutPageN.Team_description}
              </WhatWeDoTitle>

              <TeamWrapper>
                {this.props.data.allStrapiPeople.edges.map(
                  (document, index) => (
                    <div>
                      {document.node.Draft_working_article ? (
                        <Person
                          key={index}
                          Index={index}
                          Open={index === data.person ? true : false}
                          Img={
                            document.node.Img.localFile.childImageSharp.fluid
                          }
                          Name={document.node.Name}
                          Claim={noHangers(document.node.Main_Claim)}
                          Description={noHangers(document.node.Paragraph)}
                          Links={document.node.Btn}
                          Company={
                            document.node.Text_hover
                              ? document.node.Text_hover
                              : ""
                          }
                        />
                      ) : null}
                    </div>
                  )
                )}
              </TeamWrapper>
            </Wrapper>
          )}
        </ContextConsumer>
        <Footer
          lang={this.props.pageContext.locale}
          companyData={this.props.data.strapiContactPageN}
        />
      </>
    )
  }
}

export const PoznajNasQuery = graphql`
  query PoznajNasQuery($locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      Nav_btn_link
      Nav_btn_text
    }
    strapiAboutPageN(locale: { eq: $locale }) {
      SEO {
        SEO_Title
        SEO_Description
        Seo_Img {
          localFile {
            publicURL
          }
        }
      }
      Title
      Description
      Values_Title
      Values {
        Description
        Title
      }
      Values_Btn {
        Hover
        Main
        Mobile
      }
      What_we_do_Title
      What_we_do {
        Description
        Title
      }
      Slider {
        caption
        height
        width
        localFile {
          childImageSharp {
            fluid(
              quality: 100
              webpQuality: 100
              traceSVG: { background: "#ffffff", color: "#ffff00" }
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      How_do_we_work_Title
      How_do_we_work {
        Description
        Title
      }
      Image {
        localFile {
          childImageSharp {
            fluid(
              quality: 100
              webpQuality: 100
              traceSVG: { background: "#ffffff", color: "#ffff00" }
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      Testimonials_Title
      Testimonials_description
      Testimonials {
        Client
        Testimonial
      }
      Numbers_Title
      Numbers_description
      Source_link
      Numbers {
        Number
        Highlight
        Text
      }
      Team_description
      Team_title
      Structure_description
      Structure_title
      Structure {
        Description
        Title
      }
      Trivia_title
      Trivia {
        Description
        Title
      }
    }
    allStrapiPeople(
      sort: { order: ASC, fields: Name }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          Img {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                  webpQuality: 100
                  traceSVG: { background: "#ffffff", color: "#ffff00" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          Btn {
            Hover
            Main
            Mobile
            Link
          }
          Main_Claim
          Name
          Text_hover
          Paragraph
          Draft_working_article
          company {
            Title
          }
        }
      }
    }
    strapiContactPageN(locale: { eq: $locale }) {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`

export default About
