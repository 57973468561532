import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import typographySizes from "src/assets/styles/typographySizes.js"
import colors from "src/assets/styles/colors.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import ContextConsumer from "src/layouts/Context.js"
import BodyText from "src/components/global/typography/BodyText.js"
import BigText from "src/components/global/typography/BigText.js"
import BigTitleH3 from "src/components/global/typography/BigTextH3.js"
import Swipeable from "react-swipeable"
import distances from "src/assets/styles/distances.js"
import Wrapper from "src/components/global/Wrapper"
import Divider from "src/components/global/Divider.js"
import SmallTitle from "src/components/global/typography/smallTitle.js"
import CountUp from "react-countup"
import VizSensor from "react-visibility-sensor"

const SectionWrapper = styled(Wrapper)`
  position: relative;
  margin-top: 150px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 100px;
  }
`
const Title = styled(BigTitleH3)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
`

const StructureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
`

const StructureItem = styled.div`
  width: 100%;
  margin-bottom: 40px;

  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`
const StructureItemInner = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
`

const InfoWrapper = styled.div`
  width: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 75%;
  }
`

const StructureItemTitle = styled.h3`
  font-size: ${typographySizes.l}px;
  margin-bottom: 20px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.ml}px;
  }
`

const Number = styled.span`
  font-size: ${typographySizes.l}px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.ml}px;
  }
`

const StyledBodyText = styled(BodyText)`
  margin-top: 20px;
  width: 66.66%;
`

class Structure extends React.Component {
  render() {
    return (
      <SectionWrapper>
        {" "}
        <SmallTitle>{this.props.Title}</SmallTitle>
        {/* <Divider /> */}
        <Title>{this.props.Description}</Title>
        <StructureWrapper>
          {this.props.Structure.map((item, index) => (
            <StructureItem key={index}>
              {index !== 0 && <Divider />}

              <StructureItemInner>
                <Number>0{index + 1}</Number>
                <InfoWrapper>
                  <StructureItemTitle>{item.Title}</StructureItemTitle>
                  <StyledBodyText>{item.Description}</StyledBodyText>
                </InfoWrapper>
              </StructureItemInner>
            </StructureItem>
          ))}
        </StructureWrapper>
      </SectionWrapper>
    )
  }
}

export default Structure
